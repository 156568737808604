import { Box, Card, CardContent, Typography } from "@mui/material";
import "./Values.css";
import QualityIcon from "@mui/icons-material/Verified";
import EfficiencyIcon from "@mui/icons-material/AutoGraph";
import CommitmentIcon from "@mui/icons-material/Diversity3";
import React from "react";

function Values() {
  return (
    <Box id="values" className="valuesContainer">
      <h2 className="sectionTitle">Nuestros valores</h2>
      <Box className="values">
        <Card
          className="valuesCard"
          sx={{
            minWidth: {
              xs: 260,
              lg: 320,
            },
            width: {
              md: 380,
              lg: 380,
            },
            my: 2,
            mx: 1,
            boxShadow: {
              xs: "none",
              md: "24px 0 30px -8px rgba(0,0,0, 0.4), -24px 0 30px -8px rgba(0,0,0, 0.4)",
            },
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CommitmentIcon sx={{ fontSize: 64, color: "white", my: "8px" }} />
            <Typography variant="h5" component="div" className="title">
              {"Compromiso"}
            </Typography>
            <Typography variant="body2" className="description">
              {
                "Profesionalismo y vocación de servicio dirigidos al éxito de sus proyectos"
              }
            </Typography>
          </CardContent>
        </Card>

        <Card
          className="valuesCard"
          sx={{
            minWidth: {
              xs: 260,
              lg: 320,
            },
            width: {
              md: 380,
              lg: 380,
            },
            my: 2,
            mx: 1,
            boxShadow: {
              xs: "none",
              md: "24px 0 30px -8px rgba(0,0,0, 0.4), -24px 0 30px -8px rgba(0,0,0, 0.4)",
            },
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <EfficiencyIcon sx={{ fontSize: 64, color: "white", my: "8px" }} />
            <Typography variant="h5" component="div" className="title">
              {"Eficiencia"}
            </Typography>
            <Typography variant="body2" className="description">
              {
                "Nuestra pasión: lograr lo que usted desea al menor costo y en el menor tiempo"
              }
            </Typography>
          </CardContent>
        </Card>

        <Card
          className="valuesCard"
          sx={{
            minWidth: {
              xs: 260,
              lg: 320,
            },
            width: {
              md: 380,
              lg: 380,
            },
            my: 2,
            mx: 1,

            boxShadow: {
              xs: "none",
              md: "24px 0 30px -8px rgba(0,0,0, 0.4), -24px 0 30px -8px rgba(0,0,0, 0.4)",
            },
            zIndex: { md: 1 },
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <QualityIcon sx={{ fontSize: 64, color: "white", my: "8px" }} />
            <Typography variant="h5" component="div" className="title">
              {"Calidad"}
            </Typography>
            <Typography variant="body2" className="description">
              {"Nuestro sello de garantía en cada obra terminada"}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default Values;
