import { Box, Card, CardContent, Typography } from "@mui/material";
import "./Services.css";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExtensionIcon from "@mui/icons-material/Extension";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ConstructionIcon from "@mui/icons-material/Construction";
import React from "react";

function Services() {
  const services = [
    {
      icon: HomeIcon,
      title: "Obra civil",
      subtitle: "Desarrollo de proyectos de obra civil",
      description: `Obras exteriores de urbanizaciones y proyectos residenciales y
        comerciales. Construcción de viviendas, piscinas, colocación de
        tuberías etc.`,
    },
    {
      icon: ReceiptIcon,
      title: "Presupuestos",
      subtitle: "",
      description: `Confección de presupuestos detallados de proyectos constructivos`,
    },
    {
      icon: ExtensionIcon,
      title: "Remodelación y ampliación",
      subtitle: "",
      description: `Remodelación de casas de habitación, edificios, oficinas, áreas exteriores y otras obras`,
    },
    {
      icon: EngineeringIcon,
      title: "Administración de proyectos de construcción",
      subtitle: "",
      description: `Administración de proyectos constructivos en sus etapas de planeación, diseño y ejecución, controlando la administración de costos y tiempo`,
    },
    {
      icon: ConstructionIcon,
      title: "Mantenimiento de edificaciones",
      subtitle: "",
      description: `Servicio de mantenimiento recurrente como: limpieza, pintura, reparación de techos, impermeabilización, trabajos de fontanería y electricidad, etc.`,
    },
  ];
  return (
    <Box id="services" className="servicesContainer">
      <h2 className="sectionTitle">Nuestros servicios</h2>
      <Box className="services">
        {services.map((item) => (
          <ServiceCard
            key={item.title}
            icon={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
          />
        ))}
      </Box>
    </Box>
  );
}

function ServiceCard(props) {
  const Icon = props.icon;
  return (
    <Card
      key={props.title}
      sx={{
        minWidth: 200,
        maxWidth: 400,
        my: 2,
        mx: 2,
        backgroundColor: "rgba(200, 200, 200, 1)",
        // borderRadius: 0,
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Icon sx={{ fontSize: 64, color: "rgba(34, 56, 91, 1)" }} />
        <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
          {props.title}
        </Typography>
        <Typography
          sx={{ mb: 1.5, textAlign: "center" }}
          color="text.secondary"
        >
          {props.subtitle}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {props.description}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}

export default Services;
