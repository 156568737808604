import { Box, CssBaseline } from "@mui/material";
import "./About.css";
import { Fragment, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import image from "../assets/image_about_us.jpeg";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <CssBaseline />
      <Navbar />
      <Box className="flexContainer">
        <Box className="aboutContainer">
          <Box className="sectionTitle">Sobre nosotros...</Box>
          <Box className="text" sx={{ height: "calc(100% - 64px)" }}>
            Desiderátum nace como empresa consultora y constructora en el año
            2020; como muchas otras empresas en sus inicios, en una primera
            etapa, previo a nuestra constitución formal con el nombre de
            Desiderátum Construcciones, nuestro personal participa en las
            primeras obras en conjunto pero a título personal, para en el año
            2022 consolidar dicho nombre -Desiderátum Construcciones- en el área
            de Tamarindo con un equipo de trabajo estable orientado a la
            ejecución de obras civiles de alta exigencia y calidad. Desde
            entonces, la satisfacción de las aspiraciones de nuestros clientes,
            ofreciéndoles servicio técnico-profesional con eficiencia y calidad,
            guían a diario nuestra actividad.
          </Box>
        </Box>

        <Box className="missionVision">
          <Box className="mission">
            <Box className="sectionTitle">Nuesta misión:</Box>
            <Box className="text">
              Lograr plena satisfacción de nuestros clientes, entregando obras
              de alta calidad.
            </Box>
          </Box>
          <Box className="vision">
            <Box className="sectionTitle">Nuesta visión:</Box>
            <Box className="text">
              Desiderátum se proyecta en el mercado costarricense de la
              construcción como una empresa reconocida por el alto grado de
              calidad de las obras que ejecuta y su excelente relacionamiento
              con el entorno.
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box className="image">
        <img src={image} className="" alt="" />
      </Box> */}

      {/*************************************/}
      {/* <Box className="gridContainer">
        <Box className="about">
          <Box className="sectionTitle">Sobre nosotros...</Box>
          <Box className="">
            Desiderátum nace como empresa consultora y constructora en el año
            2020; como muchas otras empresas en sus inicios, en una primera
            etapa, previo a nuestra constitución formal con el nombre de
            Desiderátum Construcciones, nuestro personal participa en las
            primeras obras en conjunto pero a título personal, para en el año
            2022 consolidar dicho nombre -Desiderátum Construcciones- en el área
            de Tamarindo con un equipo de trabajo estable orientado a la
            ejecución de obras civiles de alta exigencia y calidad. Desde
            entonces, la satisfacción de las aspiraciones de nuestros clientes,
            ofreciéndoles servicio técnico-profesional con eficiencia y calidad,
            guían a diario nuestra actividad.
          </Box>
        </Box>
        <Box className="image">
          <img src={image} className="" alt="" />
        </Box>
        <Box className="mission">
          <Box className="sectionTitle">Nuesta misión:</Box>
          <Box className="">
            Lograr plena satisfacción de nuestros clientes, entregando obras de
            alta calidad.
          </Box>
        </Box>
        <Box className="vision">
          <Box className="">
            Desiderátum se proyecta en el mercado costarricense de la
            construcción como una empresa reconocida por el alto grado de
            calidad de las obras que ejecuta y su excelente relacionamiento con
            el entorno.
          </Box>
        </Box>
      </Box> */}
      <Footer />
    </Fragment>
  );
}

export default About;
