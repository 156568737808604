import Navbar from "./Navbar";
import Cover from "./Cover";
import { Fragment } from "react";
import Footer from "./Footer";
import Projects from "./Projects";
import Services from "./Services";
import Values from "./Values";

function Home() {
  return (
    <Fragment>
      <Navbar />
      <Cover />
      <Values />
      <Projects />
      <Services />
      <Footer />
    </Fragment>
  );
}

export default Home;
