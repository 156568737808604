import { Box } from "@mui/material";
import "./Projects.css";
import Slider from "react-slick";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.jpg";
import image3 from "../assets/image3.jpeg";
// import image4 from "../assets/image4.jpeg";

function Projects() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth >= 900 ? 3 : 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    className: "slider",
  };
  return (
    <div id="projects" className="projectsContainer">
      <h2 className="sectionTitle">Proyectos recientes</h2>
      <Slider {...settings}>
        <div>
          <Box className="slide" style={{ backgroundImage: `url(${image1})` }}>
            {/* <img src={image1} alt="project" /> */}
            <Box className="slideText">
              <h3>Complejo Greenleaf</h3>
              <p>Tilarán, Guanacaste</p>
            </Box>
          </Box>
        </div>
        <div>
          <Box className="slide" style={{ backgroundImage: `url(${image2})` }}>
            <Box className="slideText">
              <h3>Tamarindo Park</h3>
              <p>Santa Cruz, Guanacaste</p>
            </Box>
          </Box>
        </div>
        <div>
          <Box className="slide" style={{ backgroundImage: `url(${image3})` }}>
            <Box className="slideText">
              <h3>La Cueva de Adulam</h3>
              <p>Desamparados, San José</p>
            </Box>
          </Box>
        </div>
        {/* <div>
          <Box className="slide" style={{ backgroundImage: `url(${image4})` }}>
            <h3 className="slideText">Test</h3>
          </Box>
        </div> */}
      </Slider>
    </div>
  );
}

export default Projects;
