import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Container,
  Box,
  Toolbar,
  useScrollTrigger,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
// import logo from "../assets/logo_reverse.svg";
import logo from "../assets/logo.png";
import "./Navbar.css";
import { HashLink as Link } from "react-router-hash-link";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = window.innerWidth >= 900 ? -120 : -70;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

function Navbar(props) {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const pages = [
    { id: "projects", title: "Proyectos", useHashLink: true },
    { id: "services", title: "Servicios", useHashLink: true },
    { id: "contact", title: "Contacto", useHashLink: true },
    { id: "about", title: "Sobre nosotros" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ElevationScroll {...props}>
      <AppBar>
        <Container className="container" maxWidth="xl">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: { xs: "space-between", sm: "flex-start" },
            }}
            disableGutters
          >
            <Box onClick={navigateHome}>
              <img src={logo} className="logo" alt="logo" />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "none" },
                maxWidth: 48,
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <Link
                      key={page.id}
                      className="menuItemLink"
                      // smooth
                      // scrollOffset="100"
                      scroll={(el) => scrollWithOffset(el)}
                      to={page.useHashLink ? `/#${page.id}` : `/${page.id}`}
                    >
                      {page.title}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  sm: "flex",
                },
                justifyContent: "flex-start",
              }}
            >
              {pages.map((page) => (
                // <Button
                //   key={page}
                //   // onClick={handleCloseNavMenu}
                //   sx={{ my: 2, color: "white", display: "block" }}
                // >
                //   {"Test"}
                // </Button>
                <Link
                  key={page.id}
                  className="navLink"
                  // smooth
                  // scrollOffset="100"
                  scroll={(el) => scrollWithOffset(el)}
                  to={page.useHashLink ? `/#${page.id}` : `/${page.id}`}
                >
                  {page.title}
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}

export default Navbar;
