import { Box } from "@mui/material";
import "./Footer.css";
import logo from "../assets/logo.png";
import { HashLink as Link } from "react-router-hash-link";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = window.innerWidth >= 900 ? -120 : -70;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

function Footer() {
  return (
    <footer id="contact">
      <Box className="container">
        <Box className="footerContent">
          <Box className="footerRow">
            <Box className="footerColumn">
              <img src={logo} className="logo" alt="logo" />
            </Box>
            <Box className="footerColumn">
              <Box className="footerTitle">
                <Box className="footer-title">Enlaces</Box>
              </Box>
              <nav>
                <ul>
                  {/* <li>
                    <a href="/home">Inicio</a>
                  </li> */}
                  <li>
                    <Link
                      scroll={(el) => scrollWithOffset(el)}
                      to={`#${"projects"}`}
                    >
                      Proyectos
                    </Link>
                  </li>
                  <li>
                    <Link
                      scroll={(el) => scrollWithOffset(el)}
                      to={`#${"services"}`}
                    >
                      Servicios
                    </Link>
                  </li>
                  {/* <li>
                    <a href="/contacto/">Contacto</a>
                  </li> */}
                </ul>
              </nav>
            </Box>
            <Box className="footerColumn">
              <Box className="footerTitle">
                <Box className="footer-title">Contacto</Box>
              </Box>
              <nav>
                <ul>
                  <li>
                    <a href="https://wa.me/50661730295">(506) 61730295</a>
                  </li>
                  <li>
                    <a href="https://wa.me/50684243804">(506) 84243804</a>
                  </li>
                  <li>
                    <a href="mailto:info@desideratumcr.com">
                      info@desideratumcr.com
                    </a>
                  </li>
                </ul>
              </nav>
            </Box>
            <Box className="footerColumn">
              <Box className="footerTitle">
                <Box className="footer-title">Dirección</Box>
              </Box>
              <nav>
                <ul>
                  <li>
                    <p>Santa Cruz, Guanacaste, Costa Rica</p>
                  </li>
                </ul>
              </nav>
            </Box>
          </Box>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;
