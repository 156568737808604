import { Box } from "@mui/material";
import "./Cover.css";

function Cover() {
  return (
    <Box className="cover">
      <Box className="coverText">
        <Box className="coverIntro">
          <h1>
            Lo desea, <br /> lo construimos.
          </h1>
          <p>
            En <i>DESIDERÁTUM</i> <b>su deseo</b> se convierte en{" "}
            <b>nuestra pasión</b>.<br /> Para materializarlo fielmente ponemos a
            su disposición un equipo de profesionales en arquitectura e
            ingeniería que hará de ese propósito su actividad principal,
            combinando arte, ciencia y técnica en su trabajo.
          </p>
          <h4>Desiderátum Construcciones</h4>
        </Box>
      </Box>
      <Box className="coverImage"></Box>
    </Box>
  );
}

export default Cover;
